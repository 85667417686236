<template>
    <div class="section-title">
        <p class="section-title-title">{{title}}</p>
    </div>
</template>

<script>
    export default {
        props: {
            title: String
        }

    }
</script>

<style lang="scss" scoped>
    .section-title {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #979797;
        display: flex;
        align-items: center;

        &-title {
            color: black;
            font-size: 19px;
            font-weight: bold;
        }
    }
</style>