<template>
    <div class="result">
        <!-- BISQ不用显示总分 -->
        <unit v-if="model.type != 20" title="总分" :value="model.score" style="margin-right: 92px;"></unit>
        <unit title="结论" :value="model.result"></unit>
    </div>
</template>

<script>
    import Unit from "@c/query-result/unit.vue";
    
    export default {
        components: {
            Unit
        },

        props: {
            model: Object
        }

    }
</script>

<style lang="scss" scoped>
    .result {
        width: 100%;
        padding: 15px 0;
        display: flex;

        /* :first-child{
            margin-right: 92px;
        } */
       
    }
</style>