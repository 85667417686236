<template>
    <div class="unit">
        <p>{{title}}：</p>
        <p :class="{'no-wrap':title=='结论'}">{{value}}</p>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            value: String
        }
    }
</script>

<style lang="scss" scoped>
    .unit {
        color: black;
        font-size: 16px;
        line-height: 24px;
        display: flex;

        :last-child {
            margin-left: 5px;
        }

        >p {
            white-space: nowrap
        }
    }

    .no-wrap {
        white-space: normal !important;
    }
</style>