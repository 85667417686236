<template>
    <div v-if="model" class="bar-chart" :id="chartID"
        :style="{width: `100%`, height:`${(model.max / model.interval + 1) * 50}px`}"></div>
</template>

<script>
    export default {
        props: {
            model: Object,
        },

        data() {
            return {
                chartID: "chartID"
            }
        },

        mounted() {
            if (this.model) {
                this.chart = this.$echarts.init(document.getElementById(this.chartID));
                this.drawLine();
            }
        },

        methods: {
            drawLine() {
                this.chart.setOption({
                    grid: {
                        left: this.model.max > 10 ? 36 : 24, //两位数字空多点
                        top: 28,
                        bottom: 28,
                        right: 0,
                    },
                    xAxis: {
                        type: "category",
                        data: this.model.chartArray.map((item) => item.keyName),
                        boundaryGap: true,
                        axisLabel: {
                            color: getHexColor("rgba(0,0,0,0.6)"),
                            fontSize: 14,
                            interval: 0,

                        },
                        axisTick: false,
                        axisLine: {
                            lineStyle: {
                                color: getHexColor("rgba(24,24,24,0.25)"),
                            },
                        },
                    },
                    yAxis: {
                        max: this.model.max,
                        interval: this.model.interval,
                        axisLabel: {
                            color: getHexColor("rgba(0,0,0,0.4)"),
                            fontSize: 16,
                            margin: 15
                        },
                        axisLine: {
                            lineStyle: {
                                color: getHexColor("rgba(18,18,18,0.1)"),
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: getHexColor("rgba(18,18,18,0.1)"),
                            }
                        }
                    },
                    series: {
                        data: this.model.chartArray.map((item) => item.value),
                        type: "bar",
                        barWidth: isNaN(this.model.chartArray[0].keyName) ? 47 : 29, //非数字细一些
                        itemStyle: {
                            color: "#0052D9"
                        },
                    },
                });
            }
        },
    };
</script>